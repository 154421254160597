<template>
  <div v-if="allVideos" class="page-video">
    <div class="banner" style="background: gray">

      <video autoplay muted loop playsinline v-if="catVid">
        <source :src="catVid" />
        Your browser does not support the video tag.
      </video>

    </div>
    <div class="content">
      <!-- <input type="text" v-model="locale"> -->
      <div @click="goBack" class="back-btn mb-3">
        <span class="mr-2 d-flex align-items-center">
          <i class="arrow left"></i>
        </span>
        <!-- <img src="@/assets/img/icon/caret-square-left-regular.svg" alt /> -->
        <span>{{ $t("global.back") }}</span>
      </div>

      <h1 v-if="getCat">{{ `${getCat.name} - ${subCatTitle}` }}</h1>
      <b-row v-if="printVideos">
        <b-col cols="12" md="6" class="p-1" v-for="(el, i) in printVideos" :key="el.id">
          <VideoCard @click.native="(showModal = true), (selectedVideo = el)" :item="el" :id="i"></VideoCard>
        </b-col>
      </b-row>
      <!-- <div class="text-center mt-5 d-flex justify-content-center" v-if="allVideos.nextPageToken"
        @click="loadMore(allVideos.nextPageToken)">
        <div class="load-more">{{ $t("global.loadMore") }}</div>
      </div> -->
    </div>

    <SwiperYtModal v-if="showModal" :allVideos="allVideos" :selectedVideo="selectedVideo" class="video-modal">
    </SwiperYtModal>
  </div>
</template>

<script>
import { bus } from "@/main";
import axios from "axios";

export default {
  name: "CatVideos",
  metaInfo() {
    return {
      title: () => {
        const title = this.$route.params.vCat
        return this.makeTitle(title)
      }
    }
  },
  data() {
    return {
      showModal: false,
      selectedVideo: null,
      listId: {
        commercials: {
          zh_HK: "PLi7N86K8nRxyNqXt9ORy9vVwMosyI1-um",
          zh_CN: "PLi7N86K8nRxyNqXt9ORy9vVwMosyI1-um",
          en_US: "PLi7N86K8nRxx-ZzXhcdJq_DCEu4YwYvcC"
        },
        "corporate-videos": {
          zh_CN: "PLi7N86K8nRxxpMVSYMHc4ApMhuAtTHO7O",
          zh_HK: "PLi7N86K8nRxxpMVSYMHc4ApMhuAtTHO7O",
          en_US: "PLi7N86K8nRxxpMVSYMHc4ApMhuAtTHO7O"
        }
      },
      videos: {
        commercials: {},
        "corporate-videos": {}
      },
      printVideos: [],
      ytParams: {
        url: "https://www.googleapis.com/youtube/v3/playlistItems",
        params: {
          key: process.env.VUE_APP_YT_KEY,
          playlistId: null,
          maxResults: "50",
          part: "snippet,status",
          pageToken: null
        }
      }
    };
  },
  async created() {
    if (localStorage.getItem("videos")) {
      // console.log("fetch video from local")
      this.videos = JSON.parse(localStorage.getItem("videos"))
      this.printVideos = this.videos[this.$route.params.vCat][this.$i18n.locale]
    }
    if (!localStorage.getItem("videos") || !JSON.parse(localStorage.getItem("videos"))[this.$route.params.vCat].length || JSON.parse(localStorage.getItem("time")) + 900000 < Date.now()) {
      this.ytParams.params.playlistId = this.listId[this.$route.params.vCat][this.$i18n.locale]
      const { data } = await axios(this.ytParams)
      this.printVideos = data.items;
      this.videos[this.$route.params.vCat][this.$i18n.locale] = data.items;
      localStorage.setItem('videos', JSON.stringify(this.videos));
      localStorage.setItem("time", JSON.stringify(Date.now()))
    }
  },
  computed: {
    allCats() {
      return this.$t("categories").data;
    },
    getCat() {
      return this.allCats.find(el => el.slug === this.$route.name);
    },
    catVid() {
      let res = "";
      try {
        res = require(`@/assets/video/${this.getCat.id}.mp4`);
      } catch (error) {
        console.log(error);
      }
      return res;
    },
    allVideos() {
      return this.$store.state.data.youtubeVideos;
    },
    allMaxVideos() {
      return this.allVideos?.items?.filter(
        el => el.status.privacyStatus !== "private"
      );
    },
    subCatTitle() {
      if (this.$t("categories").subCats.length) {
        // console.log(this.$t('categories'))
        return this.$t("categories").subCats.find(
          ({ slug }) => slug === this.$route.params.vCat
        ).name;
      }
      return "";
    }
  },
  methods: {
    makeTitle(slug) {
      var words = slug.split('-');
      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
      return words.join(' ');
    },
    async loadMore(token) {
      this.ytParams.params.pageToken = token;
      const res = await axios(this.ytParams);
      res.data.items.forEach(item => {
        this.allVideos.items.push(item);
      });
      this.allVideos.nextPageToken = res.data.nextPageToken;
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  components: {
    VideoCard: () => import("./partials/VideoCard.vue"),
    SwiperYtModal: () => import("./partials/SwiperYtModal.vue")
  },
  watch: {
    '$i18n.locale': {
      async handler(newVal, oldVal) {
        if (!this.videos[this.$route.params.vCat][newVal]) {
          this.videos[this.$route.params.vCat][newVal] = []
        }
        if (!this.videos[this.$route.params.vCat][newVal].length) {
          console.log("fetchting new videos")
          this.ytParams.params.playlistId = this.listId[this.$route.params.vCat][newVal]
          const { data } = await axios(this.ytParams)
          this.videos[this.$route.params.vCat][newVal] = data.items
          localStorage.setItem("videos", JSON.stringify(this.videos));
        }
        this.printVideos = this.videos[this.$route.params.vCat][newVal];
      },
      deep: true
    }
  },
  async mounted() {
    // this.ytParams.params.playlistId = this.listId[this.$route.params.vCat][this.$i18n.locale]
    // const { data } = await axios(this.ytParams)
    // this.videos[this.$i18n.locale] = data.items;
    // console.log(this.videos)
    // console.log(this.locale)
    bus.$on("closeYTModal", () => {
      this.showModal = false;
    });
    // console.log("tvc".toUpperCase() === "TVC");
  }
};
</script>

<style lang="scss">
.page-video {
  padding: 150px 0;

  h1 {
    font-size: 1.5rem;
  }

  .banner {
    height: 250px;
    margin-bottom: 80px;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: 768px) {
    .banner {
      margin-bottom: 130px;
      height: 600px;
    }

    .page-video {
      padding: 150px 0;

      h1 {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
